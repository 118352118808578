import React, { createContext, useState, useContext } from 'react';


const FloatingComponentContext = createContext();


export const useFloatingComponent = () => useContext(FloatingComponentContext);


export const FloatingComponentProvider = ({ children }) => {
    const [showFloatingComponent, setShowFloatingComponent] = useState(false);
    const [message, setMessage] = useState(''); 
    const [paymantDetailsData, setPaymentDetailsData] = useState("");

    const showComponent = () => setShowFloatingComponent(true);
    const hideComponent = () => setShowFloatingComponent(false);
    const updateMessage = (newMessage) => setMessage(newMessage);

    return (
        <FloatingComponentContext.Provider value={{ showFloatingComponent, showComponent, hideComponent, message, updateMessage, setPaymentDetailsData, paymantDetailsData }}>
            {children}
        </FloatingComponentContext.Provider>
    );
};
