import React, { useState } from "react";
import "./login.css";
import arthWhite from "../../assets/img/arth_logo_white.svg";
import { useNavigate } from "react-router-dom";
import loginService from "../../service/login.service";
import { UserService } from "../../service/user-service";
import sha256 from "crypto-js/sha256";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const navigate = useNavigate();
  const apiservice = new UserService();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleEmailInput = (event) => {
    setEmail(event.target.value);
  };

  const handlePwdInput = (event) => {
    setPassword(event.target.value);
  };

  const handleLogin = async () => {
    const hashedPassword = sha256(password).toString();
    const login_data = {
      userName: email,
      password: hashedPassword,
    };
    //getUser
    try {
      const response = await apiservice.login(login_data);

      const AuthenticationResult = response?.data?.AuthenticationResult;
      if (
        AuthenticationResult &&
        AuthenticationResult.IdToken &&
        AuthenticationResult.RefreshToken
      ) {
        localStorage.setItem("auth_token", AuthenticationResult.IdToken);
        localStorage.setItem("OVCLOGGEDIN", JSON.stringify(true));
        localStorage.setItem("refreshToken", AuthenticationResult.RefreshToken);

        const accessToken = AuthenticationResult.AccessToken;
        localStorage.setItem("accessToken", accessToken);

        apiservice
          .getUser(email)
          .then((res) => {
            localStorage.setItem("ovcData", JSON.stringify(res.data));
            localStorage.setItem("email", email);
            const ovcRole =  res.data.role

            apiservice
              .getRole(res.data.role)
              .then((res) => {
                const permissions = res.data.permissions;

                if (permissions.includes("Edit_INFO")) {
                  localStorage.setItem("roleovc", ovcRole);
                  navigate("/dashboard");
                } else if (permissions.includes("VIEW_INFO")) {
                  localStorage.setItem("roleovc", ovcRole);
                  navigate("/dashboard");
                } else if (permissions.includes("OVC")) {
                  localStorage.setItem("Permissions", JSON.stringify(res.data?.permissions));
                  localStorage.setItem("roleovc", ovcRole);
                  navigate("/dashboard");
                } else {
                  setError("You don't have access to login");
                  localStorage.clear();
                }
              })
              .catch((err) => {});
          })
          .catch();
      }
    } catch (error) {
      console.log("gg", error)
      setError("An error occurred. Please try again.");
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="">
        <div className="card login-card">
          <div className="image-side">
            <div>
              <img className="logo" src={arthWhite} alt="ARTH Logo" />
              <h2
                style={{ color: "#fff", fontSize: "1.4rem", fontWeight: "300" }}
              >
                ARTH - Powering Micro Businesses
              </h2>
            </div>
          </div>
          <div className="form-side">
            <div
              style={{
                marginBottom: "1.4rem",
                fontSize: "1rem",
                fontWeight: "300",
              }}
            >
              Login
            </div>
            <div className="form-field">
              <div className="login-password-text">Email</div>
              <input
                type="email"
                className="form-input-field"
                onChange={handleEmailInput}
                value={email}
                placeholder="Enter your email"
              />
            </div>
            <div className="form-field">
              <div className="login-password-text">Password</div>
              <input
                type="password"
                className="form-input-field"
                onChange={handlePwdInput}
                value={password}
                placeholder="Enter your password"
              />
            </div>
            {error && <div className="login-error" style={{color:"red", fontWeight:"bold"}}>{error}</div>}
            <div className="login-button-position">
              <button className="login-button" onClick={handleLogin}>
                <span>Login</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
